@import '../../styles/colors.scss';

.chat-inbox-container {
  position: fixed;
  bottom: 0;
  left: auto;
  right: 0;
  margin-left: auto;
  z-index: 200;
  background-color: #0D2440;
  color: white;
  width: 400px;
  margin-right: 4.16666667%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  .chat-inbox-title {
    padding: 1rem 2rem;
    width: 100%;
    font-family: 'Avenir Medium', Helvetica, sans-serif;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column-reverse;
  }

  .chat-inbox-panel {
    .ant-collapse-header {
      background-color: $darkBlue;
      border-color: $darkBlue;
      color: white;
      padding: 1rem 2rem;
      width: 100%;
      font-family: 'Avenir Medium', Helvetica, sans-serif;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .arrow-panel-icon {
        padding: 5px;

        .a {
          fill: white !important;
        }
      }
    }

    .all-chat-inbox {
      height: 100%;

      .ant-tabs-content-holder {
        //overflow: auto;
        //max-height: 26rem;
      }
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      position: relative;
      display: flex;
      transition: transform 0.3s;
      width: 100%;
    }

    .ant-tabs-tab {
      width: 50%;
      text-align: center;
      margin: auto;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      margin: auto;
    }

    .list-overflow {
      overflow: auto;
      max-height: 26rem;
      height: 26rem;
    }

    .ant-list-empty-text {
      padding-top: 8rem;
    }

    .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
      margin-bottom: 0;
    }

    .new-message {
      background-color: $activeCard;
    }

    .ant-list-item {
      padding: 0;
    }
  }

  .pending-item {
    position: relative;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background: $lightGreen;
    z-index: 10000;
    margin-left: 1rem;
  }

  .pending-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .empty-chat {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 390px;
  }

  .ant-tabs-tab {
    background-color: $darkBlue;
    color: $lightGray;
    font-family: 'Avenir Heavy', Helvetica, sans-serif;
  }

  .ant-tabs-ink-bar {
    background-color: $darkBlue;
  }

  .ant-tabs-tab-active {
    color: white;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }

  .ant-tabs, .ant-collapse-item:last-child > .ant-collapse-content {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .ant-collapse {
    border-top: 8px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
}

.chat-inbox-container .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid $darkBlue !important;
}
