@import "colors.scss";

$sizes: 20;

@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 0.5rem;
    /* margin #{$margin} */
    .m-#{$i} {
      margin: $margin;
    }
    .ml-#{$i} {
      margin-left: $margin;
    }
    .mr-#{$i} {
      margin-right: $margin;
    }
    .mt-#{$i} {
      margin-top: $margin;
    }
    .mb-#{$i} {
      margin-bottom: $margin;
    }
    .mx-#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my-#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}

@include margin-classes;

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.5rem;
    /* padding #{$padding} */
    .p-#{$i} {
      padding: $padding;
    }
    .pl-#{$i} {
      padding-left: $padding;
    }
    .pr-#{$i} {
      padding-right: $padding;
    }
    .pt-#{$i} {
      padding-top: $padding;
    }
    .pb-#{$i} {
      padding-bottom: $padding;
    }
    .px-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}

@include padding-classes;

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
  width: 100%;
  justify-content: center;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  min-height: 100vh;
}

.mt-10 {
  margin-top: 10px;
}

.bold {
  font-weight: bold;
}

.font-regular {
  font-size: 14px;
}

.font-xsmall {
  font-size: 10px;
}

.font-small {
  font-size: 12px;
}

.font-medium {
  font-size: 16px;
}

.font-large {
  font-size: 20px;
}

.font-xlarge {
  font-size: 24px;
}

.font-xlarge-medium {
  font-size: 26px;
}

.font-xxlarge {
  font-size: 32px;
}

.agenda {
  font-size: 22px;
  font-family: "Avenir Medium";
}

.headline {
  font-size: 40px;
  font-family: "Avenir Book";
}

.subline {
  font-size: 22px;
  font-family: "Avenir Italic";
}

.subheading {
  font-size: 22px;
  font-family: "Avenir Medium";
}

.fliesstext {
  font-size: 14px;
  font-family: "Avenir Book";
}

.navigation {
  font-size: 14px;
  font-family: "Avenir Medium";
}

.background-color-primary {
  background-color: $chambray;
}

.color-primary {
  color: $darkBlue;
}

.color-santas-grey {
  color: $santasGray !important;
}

.color-trout {
  color: $trout;
}

.display-inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.display-inline {
  display: inline;
}

.feedback-error {
  color: $valencia;
}

.feedback-success {
  color: $darkBlue;
}

.feedback-warning {
  color: $fireBush;
}

.feedback-info {
  color: $darkBlue;
}

.password-background {
  background: $lightBlueHover;
}

.password-image {
  .login-form {
    margin-top: -10rem;
  }
}

.input-custom {
  border: 1px solid $lightGray;
  border-radius: 8px;
  padding: 0 10px;
}

.tertiary-btn {
  cursor: pointer;
  color: $panelSubtitle;
  text-transform: uppercase;
  font-family: "Avenir Medium";
}

.link-hover:hover, .tertiary-btn:hover {
  cursor: pointer;
  text-decoration: underline auto;
}

.no-style {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.documents-speed-match {
  padding: 0 4px!important;
}

textarea:disabled{
  &.ant-input{
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
  }
}

.custom-form {
  .hide-value {
    .ant-select-selection-item {
      &:after {
        display: none !important;
      }
      .ant-select-selection-item-content {
        display: none !important;
      }
    }
  }
  .input-read-only:not([disabled]){
    pointer-events: none !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .ant-input-affix-wrapper, .ant-picker, .ant-input, .ant-select-selection-overflow, .ant-select-multiple .ant-select-selection-placeholder,
    .ant-input-suffix, .ant-picker-input > input, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker {
      background-color: $inputReadOnly !important;
    }

    .ant-select-selector {
      background-color: $inputReadOnly !important;
    }
  }

  .input-read-only.ant-input-affix-wrapper {
    background-color: $inputReadOnly !important;
  }

  .ant-picker.input-read-only {
    background-color: $inputReadOnly !important;
  }

  .ant-input.input-read-only {
    background-color: $inputReadOnly !important;
  }
}

.link-disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.color-caption {
  color: $blue;
}

.expand-all-btn {
  border: none;
  font-family: 'Avenir Medium', Helvetica, sans-serif;
  color: $mediumDarkBlue;
  cursor: pointer;
  text-transform: uppercase;
}

.expand-all-btn:hover {
  border: none;
  text-decoration: underline $mediumDarkBlue;
}

.no-display {
  display: none !important;
}

.input-focus {
  border: 1px solid $inputFocus !important;
}

.upload-focus {

  .input-filled {
    background: $draft !important;
    min-height: 30px;
  }

  .enabled-close {
    display: none;
  }
}

.upload-progress {
  .input-filled {
    border: 1px solid $blue !important;
    min-height: 30px;
  }
}

.upload-focus-check {

  .input-filled {
    background: $pastelGreen !important;
    min-height: 30px;
  }

  .enabled-close {
    display: none;
  }
}

// required tag
.with-required-mark {
  label {
    color: $darkBlue;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: $blue;
    order: 1;
  }
}

@media (max-width: 759px) {
  .password-image {
    margin-top: 0;

    .login-form {
      margin-top: 0;
    }
  }
}

@function get-vw($target) {
  $vw-context: (1000*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
