@import '../../styles/colors.scss';

svg.close-icon-notification-item {
  top: -13px;
  right: -15px;
  position: absolute;

  .a {
    fill: white !important;
  }
}

.close-icon-notification {
  .ant-notification-notice-description {
    font-size: 16px;
    padding-right: 2rem;
    margin-top: -0.5rem;
  }
}

.close-icon-notification-approval {
  .ant-notification-notice-message {
    color: white;
    font-size: 14px;
    font-family: 'Avenir Book';
  }
  .ant-notification-notice-description {
    font-size: 16px;
    font-family: 'Avenir Medium';
    color: white;
  }

  svg.close-icon-notification-item {
    top: -13px;
    right: -15px;
    position: absolute;

    .a {
      fill: white !important;
    }
  }
}
