.responsive-logo {
  cursor: pointer;
  z-index: 100;
  width: 8.5rem;
  max-width: 450px;
  padding: 0.5rem 0;
  height: fit-content;
  max-height: 5rem;
}

.responsive-header-logo {
  max-width: 450px;
  z-index: 100;
  cursor: pointer;
  width: 8.5rem;
  height: fit-content;
  max-height: 5rem;
}


@media (max-width: 759px) {
  .responsive-logo {
    width: 6rem;
  }
}
