@font-face {
  font-family: 'Avenir Medium';
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Avenir-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Book';
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Avenir Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Book Oblique';
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Avenir Book Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Light";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Avenir Regular.woff") format("woff");
}

@font-face {
  font-family: "Avenir Italic";
  src: url("./fonts/Avenir Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Black";
  src: url("./fonts/Avenir Black.otf") format("truetype");
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("./fonts/Avenir Heavy.ttf") format("truetype");
}
