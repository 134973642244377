@import 'colors';
@import 'variables';
@import 'avenir';

html,
body {
  margin:0px;
  height:100%;
}

body {
  font-family: 'Avenir Book', Helvetica, sans-serif;
  color: $trout;
  font-weight: normal;
}
