@import '../../../styles/colors.scss';


.chats-inbox {
  display: flex;
  flex-direction: column;

  .inbox-item-container {
    width: fit-content;
  }

  .inbox-item {
    border: 1px solid $blue;
    background-color: $blue;
    color: white;
    width: fit-content;
    border-radius: 8px;
    margin: 0 1rem
  }

  .inbox-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .inbox-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .inbox-right {
    border: 1px solid $blue;
    background-color: white;
    color: $blue;
  }

  .input-chat-inbox {
    background-color: $grayBackground;
    display: flex;
    flex-direction: row;
    align-items: center;

    .send-message-icon {
    }
  }

  .input-chat-container {
    overflow: auto;
    height: 360px;
    max-height: 360px;
    flex-grow: 1;
    position: relative;
    bottom: 0;
  }

  .input-chat-container-empty {
    flex-grow: 1;
    position: relative;
    bottom: 0;
    height: 26rem;
  }

  .user-data {
    border-bottom: 1px solid $mediumDarkBlue;
    background-color: $mediumDarkBlue;
    padding: .25rem 1rem;
    width: 100%;
    color: white;
    font-family: 'Avenir Medium', Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0;
    rigth: 0;
  }

  .check-message-icon {
    margin-left: 5px;

    svg {
      height: 10px;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
