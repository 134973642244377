@import "colors.scss";

#root {
  height: 100%;
}

.ant-input {
  border: none;
  border-radius: 8px;
  padding: 0 10px;
  height: 40px;
}

.ant-input-suffix {
  color: $lightSuffix;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 0;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active {
  color: inherit;
  background: #F0F7FA;
  font-family: 'Avenir Medium', Helvetica, sans-serif !important;
}

.ant-menu-submenu-popup > .ant-menu {
  border-radius: 5px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
}


.ant-input-affix-wrapper {
  border: none;
  border-radius: 8px;
  padding: 0 10px;
  height: 40px;
}

.ant-input-affix-wrapper > input.ant-input {
  height: auto;
}

.ant-input-borderless {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $lightGray;
  height: 40px;
}

.ant-input-borderless:hover, .ant-input-borderless:focus, .ant-input-borderless-focused, .ant-input-borderless-disabled, .ant-input-borderless[disabled] {
  border-bottom: 1px solid $lightGrayHover;
}

.ant-form-item {
  margin-bottom: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px !important;
  border: none;
  min-height: 40px;
  padding: 0 10px;
}

.ant-select-selection-placeholder {
  font-size: 14px;
}

.ant-checkbox-wrapper {
  line-height: 1rem !important;
}

.ant-picker {
  border: none;
  border-radius: 8px !important;
  min-height: 40px;
  padding: 0 10px;
  width: 100%;
}

.ant-btn-primary {
  background: $lightGreen;
  color: $darkBlue;
  border-color: $lightGreen;
  margin-top: 1.0625rem;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 20px;
  padding: 0.625rem 2rem;
  height: 2.5rem;
  font-weight: bold;
  min-width: 248px;
}

.ant-btn-primary:hover {
  background: $lightGreen;
  color: $darkBlue;
  border-color: $lightGreen;
  opacity: .7 !important;
}

.ant-btn-primary:focus {
  background: $lightGreen;
  color: $darkBlue;
  border-color: $lightGreen;
  opacity: 1;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  background: $lightGreen;
  color: $darkBlue;
  border-color: $lightGreen;
  opacity: .5 !important;
}

.ant-btn-secondary {
  background: white;
  color: $darkBlue;
  border-color: $darkBlue;
  margin-top: 1.0625rem;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 20px;
  padding: 0.625rem 2rem;
  height: 2.5rem;
  font-weight: bold;
  min-width: 248px;
}

.ant-btn-secondary:hover {
  background: white;
  color: $darkBlue !important;
  border-color: $darkBlue !important;
  opacity: .7 !important;
}

.ant-btn-secondary:focus {
  background: white;
  color: $darkBlue !important;
  border-color: $darkBlue !important;
  opacity: 1;
}

.ant-btn-secondary[disabled], .ant-btn-secondary[disabled]:hover, .ant-btn-secondary[disabled]:focus, .ant-btn-secondary[disabled]:active {
  background: white;
  color: $darkBlue !important;
  border-color: $darkBlue !important;
  opacity: .5 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $checkbox;
  border-color: $checkbox;
}

.ant-form-item-control-input {
  height: inherit;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 1px solid $lightGray;
  padding: .4rem 0;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  font-size: 12px;
}

.tool-tip {
  max-width: 310px !important;

  .ant-tooltip-inner {
    border: 0;
    padding: 14px;

    p {
      font-size: 10px;
      margin-bottom: 0;
      color: $darkBlue;

      strong {
        font-family: 'Avenir Heavy';
      }

      font-family: 'Avenir Book';
    }
  }
}

.ant-tooltip-inner {
  max-width: 300px;
  border: 1px solid $blue;
  background-color: white;
  color: $blue;
}

.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border: 1px solid red;
}

.ant-popover-inner-content {
  max-height: 15rem;
  overflow: auto;
  padding-top: 0;
  padding-left: 0;
  padding-right: .5rem;
}

.ant-popover-inner {
  padding: 1rem;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 12px;
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  width: 100%;
}

.ant-picker-clear > * {
  svg {
    width: 18px;
    height: 18px;
  }
}

.textarea-read-only {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 4px !important;
}

::-webkit-scrollbar-thumb {
  background: $text;
  border-radius: 15px;
}

/*
.ant-form-horizontal .ant-form-item-control {
  min-width: unset;
}*/
