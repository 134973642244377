$darkBlue: #002B49;
$borderBlue: #3A556B;
$mediumDarkBlue: #667F91;
$inputFocus: #66A8D6;
$lightGray: #D8D6D9;
$lightGrayHover: #E8E7E7;
$lightSuffix: #99AAB6;
$mismatchCard: #EFEFEF;
$activeCard: #F1FFF4;
$lightGreen: #74FA92;
$bostonBlue: #3c93aa;
$pastelGreen: #E3FEE9;
$offMarket: #F8E4E2;
$draft: #FAF5BE;
$green: #02C186;
$error: #E54C51;
$tagGreen: #E4F2EA;
$lightBlue: #F0F7FA;
$borderCard: #D6E6ED;
$textReadOnly : #00293D;
$actionsColor: #33556D;
$grayTitle: #EDEEF7;
$blueDark: #002B49;
$inputReadOnly: #F8F8F8;
$inputNegotiation: #E4F0F7;
$borderCardText: #002B4999;
$mediumBlue: #BAD8E533;
$borderPastel: #F1F7FA;
$pastelBlue: #BAD8E5;
$pastelMediumBlue: #CCE2F1;
$circlesGreen: #DBF3F3;
$pink: #FCE9FB;
$blueDot: #2280FB;
$uploadBtn: #002B49;
$uploadBtnBorder: #002B49;
$uploadSpeedMatch: #136EBB;
$lightBlueHover: rgba(186, 216, 229, 0.1);
$panelSubtitle: #202A38;
$white: #FFFFFF;
$tagBackground: #68B7CC;
$gunmetal: #505655;
$chambray: #2254B9;
$secondChambray: #334D80;
$alabaster: #F9F9F9;
$santasGray: #9EA0A5;
$trout: #4D4F5C;
$inputDisabled: #B2B2B2;
$blue: #006ebb;
$checkbox: #006EBB;
$valencia: #D63E43;
$chateauGreen: #3BAD4B;
$alto: #d9d9d9;
$fireBush: #E3AA30;
$pigeonPost: #B1B7D8;
$zircon: #F7F8FF;
$seaGreen: #2D9C3C;
$mystic: #EAEDF3;
$astronaut: #303C7E;
$sanMarino: #505DA8;
$nobel: #B5B5B5;
$tuna: #3E3F42;
$white: white;
$blueBell: #8A93C5;
$ronchi: #E9BB59;
$athensGray: #E1E3E9;
$silverChalice: #B1B1B1;
$blueZodiac: #0D2440;
$anzac:#E7B64D;
$mariner: #1F65C0;
$whiteBackground: white;
$grayBackground: #D8DCE6;
$headerBackground: #F7F8FA;
$label: #9EA0A5;
$ceruleanBlue: #2254B9;
$border: #D8DCE6;
$text: #4D4F5C;
$footerLink: #3C4B9E;
$geyser: #D8DCE6;
$iron: #CED0D4;
$midNightBlue: #1A3670;
$solitude: #E8EAF0;
$mischka: #ADB0B8;
$shipCove: #8090B0;
$prussianBlue: #002060;
$border-default: #707070;
$iconDefault: #B3BCCF;
$mercury: #E8E8E8;
$linkWater: #CCD2DF;
$crema: #D1AE72;
$lightBlueMagenta: #E4E4FD;
$lighBlack: rgba(0, 0, 0, 0.33);
$metallicGold: #D1AE72;
$cremaDark: #4C4D4C;
$brown: #4E4D4E;
$lightGrayBlue: #DDEDF4;
$gold: #fffbe6;

:export {
  nobel: $nobel;
  tuna: $tuna;
  white: $white;
  trout: $trout;
  astronaut: $astronaut;
  chambray: $chambray;
  blueBell: $blueBell;
  fireBush: $fireBush;
  ronchi: $ronchi;
  ceruleanBlue: $ceruleanBlue;
  blueZodiac: $blueZodiac;
  anzac: $anzac;
  geyser: $geyser;
  mariner: $mariner;
  whiteBackground: $whiteBackground;
  grayBackground: $grayBackground;
  headerBackground: $headerBackground;
  label: $label;
  border: $border;
  text: $text;
  footerLink: $footerLink;
  iron: $iron;
  midNightBlue: $midNightBlue;
  mischka: $mischka;
  shipCove: $shipCove;
  prussianBlue: $prussianBlue;
  linkWater: $linkWater;
  iconDefault: $iconDefault
}
