@import '../../../styles/colors.scss';

.terms-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 60vw !important;
  animation-duration: 0s !important;

  .ant-modal-body {
    max-height: 60vh;
    height: 100%;
    overflow: auto;
  }
}


@media (max-width: 769px) {
  .terms-modal {
    width: 100vw !important;
    animation-duration: 0s !important;

    .ant-modal-body {
      max-height: 70vh;
      height: 100%;
    }
  }
}
