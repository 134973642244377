@import '../../styles/colors.scss';

.translation-selector {
  width: 4rem;

  .language {
    text-align: center;
    height: 1rem;
    font-size: 14px;
    line-height: 1rem;
    cursor: pointer;
    color: $darkBlue;

    &.selected {
      color: $darkBlue;
      font-family: 'Avenir Black', Helvetica, sans-serif;
    }
  }

  .item-border-box {
    border-right: 1px solid $darkBlue;
  }
}
