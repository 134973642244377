@import '../../styles/colors.scss';

.header {
  width: 100%;
  min-height: 7vh;
  background: #FFFFFF;
  box-shadow: 0 2px 5px #00000026;
  align-items: center;
  position: relative;
  z-index: 2;

  .your-market {
    font-family: 'Avenir Medium', Helvetica, sans-serif;
    font-size: 12px;
    color: $darkBlue;
    padding-left: 32px;
  }

  .header-user-information {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 4.16666667%;
    z-index: 100;

    .user-name {
      font-size: 14px;
    }
    .user-description{
      font-family: 'Avenir Medium', Helvetica, sans-serif;
      font-size: 12px;
    }
    .user-icon {
      .a {
        fill: black;
        opacity: 0.2;
      }
    }

    .icon-user {
      padding-left: 32px;
    }
    .user-info-container {
      margin-left: 32px;
      .menu {
        border: none;

        .ant-menu-item-selected {
          background: white;
          display: flex;
          align-content: center;
        }
        .ant-menu-submenu-arrow {
          display: none;
        }
      }

      .sub-menu {
        position: absolute;
        padding: 0 26px!important;
        width: 280px;

        .ant-menu-inline{
          margin: 0;
        }
      }
    }

    .user-information-container {
      flex-direction: column;
      padding-top: 1rem;
      text-transform: uppercase;
      color: $darkBlue;
      min-width: 10rem;

      span {
        margin: 0;
        padding: 0 30px 0 16px;
        height: 1rem;
        line-height: 0;
      }

      .user-description {
        font-size: 12px;
      }
    }

    .down-arrow {
      cursor: pointer;
      align-items: center;

      .down-arrow-icon {
        transform: rotate(180deg);
        width: 18px;
        height: 13px;
        .a {
          fill: $darkBlue;
        }
      }
    }

    .user-avatar {
      fill: black !important;
    }
  }
}

.ant-menu-item .ant-menu-item-icon {
  margin: 0 0 -.3rem 0;
}

.children-container {
  height: 92vh;
}

.dropdown-header {
  align-items: center;
  width: 100%;
  bottom: -1.5rem;
  font-family: 'Avenir Book', Helvetica, sans-serif;
  font-size: 14px;

  :hover {
    font-family: 'Avenir Medium', Helvetica, sans-serif !important;
  }
}

@media (max-width: 800px) {
  .header {
    .your-market {
      display: none;
    }

    .header-user-information {
      .user-info-container {
        .user-icon {
          padding-right: .5rem;
        }

        .user-information-container {
          display: none;
        }
      }
    }
  }
}


